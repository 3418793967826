import logo from './logo.svg';
import './App.css';
import ReactDOM from 'react-dom/client';
// import dev from './dev';
import Dev from './dev';



function App() {
  return (
    <div className="App">
      <Dev></Dev>
    </div>
  );
}

export default App;
